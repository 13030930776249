import React from 'react'
const Edit = () => {
    const queryString = global.window ? global.window.location.search : '';
  
    // Parse the query string
      const queryParams = new URLSearchParams(queryString);
      const sessionId = queryParams.get('session');
      const schedulerId = queryParams.get('schedulerId');
    return <div className="flex flex-col items-center justify-center h-screen">
    <div className="flex flex-col items-center justify-center gap-16">
        {schedulerId}
        {sessionId}
        <div>Edit</div>
        <button>Save</button>
    </div>
    </div>
}

export default Edit 